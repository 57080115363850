<template>
  <v-card>
    <v-card-title>{{ value.name }}</v-card-title>
    <v-card-text>
      <v-select v-model="role" :items="permissions" item-text="role" item-value="role" label="Permission">
        <template v-slot:item="{ item }">
          <v-list-item-content style="max-width:600px">
            <v-list-item-title>{{ item.role }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ item.access }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>
      <v-select v-model="notify" :items="notificationOptions" :disabled="role === 'Editor'" :persistent-hint="role === 'Editor' ? true : false" :hint="role === 'Editor' ? 'Editors cannot receive submission notifications' : ''" label="Notification Setting">
        <template v-slot:item="{ item }">
          <v-list-item-content style="max-width:600px">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.subtext">{{ item.subtext }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>
      <v-checkbox v-if="notify === 'Yes' || notify === 'Limited'" v-model="includeData" label="Include Data In Notification">
        <template v-slot:append-outer>
          <v-dialog v-model="includeDataDialog" width="400">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="info">fas fa-question-circle</v-icon>
            </template>
            <v-card>
              <v-card-text>If any of the information in this form is personally identifiable information, then do not have the form send the submitted data in an email. Email is an insecure method by which to send this information.</v-card-text>
              <v-card-actions>
                <v-btn text @click="includeDataDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-checkbox>
      <template v-if="notify === 'Limited'">
        <p>Will send an email when all of the following criteria are met:</p>
        <access-block-criteria-row v-for="(item, index) in notifySettings" v-model="notifySettings[index]" :key="'setting-' + index" :form-fields="formFields" @remove="notifySettings.splice(index, 1)"></access-block-criteria-row>
        <v-btn @click="notifySettings.push({ field: '', value: '' })">Add Criteria</v-btn>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="error" @click="$emit('remove')">Remove</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {
    AccessBlockCriteriaRow: () => import('./AccessBlockCriteriaRow')
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {
        return {
          name: '',
          email: '',
          pidm: 0,
          role: '',
          notify: 'No',
          notifySettings: []
        }
      }
    },
    formFields: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup (props, { root, emit }) {
    const role = computed({
      get: () => props.value.role || '',
      set: (val) => {
        let obj = { role: val }
        if (val === 'Editor') {
          obj.notify = 'No'
        }
        emit('input', Object.assign({}, props.value, obj))
      }
    })
    const notify = computed({
      get: () => props.value.notify || '',
      set: (val) => {
        emit('input', Object.assign({}, props.value, { notify: val }))
        if (val === 'Limited' && notifySettings.value.length === 0) {
          notifySettings.push({ field: '', value: '' })
        }
      }
    })
    const includeData = computed({
      get: () => props.value.includeData || false,
      set: (val) => {
        emit('input', Object.assign({}, props.value, { includeData: val }))
      }
    })
    const includeDataDialog = ref(false)
    const notifySettings = ref([])

    watch(() => props.value, () => {
      if (props.value != null && typeof (props.value) === 'object' && 'notifySettings' in props.value) {
        notifySettings.value = props.value.notifySettings || []
      }
    })
    watch(notifySettings, () => {
      if (JSON.stringify(notifySettings.value) !== JSON.stringify(props.value.notifySettings)) {
        emit('input', Object.assign({}, props.value, { notifySettings: notifySettings.value }))
      }
    })

    const permissions = ref([
      {
        role: 'Admin',
        text: 'Has full access to form, including editing the form and viewing submissions.'
      },
      {
        role: 'Editor',
        text: 'Has access to modify the form but cannot modify permissions or view form submissions.',
        access: 'Can access the Form, Confirmation, and Revisions tabs.'
      },
      {
        role: 'Reviewer',
        text: 'Has access to submissions but cannot modify the form or change permissions.',
        access: 'Can access the Submissions and Revisions tabs (but has no permissions on the latter).'
      }
    ])
    const notificationOptions = ref([
      { text: 'No Notifications', subtext: 'Do not send email notifications', value: 'No' },
      { text: 'Every Submission', subtext: 'Send email notification for every submission', value: 'Yes' },
      { text: 'Daily Digest', subtext: 'Send one email digest on weekday mornings for submissions made since the last notification', value: 'Digest' },
      { text: 'When Data Matches', subtext: 'Only send emails when certain fields in the form match given values', value: 'Limited' }
    ])

    return {
      role,
      notify,
      includeData,
      includeDataDialog,
      notifySettings,
      permissions,
      notificationOptions
    }
  }
}
</script>
